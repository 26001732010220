






























































































































































import { computed, defineComponent, reactive, Ref, ref, set } from '@vue/composition-api'
import { Pagination, Tag, Upload, TableColumn, Table, Tooltip } from 'element-ui'
import { exportTokensApi, getLastImportTokensRecordApi, getProjectApi, getScanLinkApi, getTokensApi, getTokenTemplate, importTokensApi } from '@/api/project'
import { exportFile, showError } from '@/utils/common'
import ProjectHeader from './components/ProjectHeader.vue'
import { fileTypes, uploadHandle } from '@/utils/upload'
import LoadMore from '@/components/LoadMore.vue'
import { ProjectTokenStatus } from '@/api/project/model'
import config from '@/config'
import qrcode from 'qrcode'
import { useUserStore } from '@/pinia/modules/user'
import { storeToRefs } from 'pinia'
import { UserRole } from '@/api/user/model'
import InputBox from '@/components/InputBox.vue'
import _ from 'lodash'

export default defineComponent({
    components: {
        [Tag.name]: Tag,
        [Upload.name]: Upload,
        [Pagination.name]: Pagination,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        ProjectHeader,
        LoadMore,
        InputBox,
    },
    setup(props, { root }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)
        console.log(userInfo)

        const detailObj: any = reactive({})
        const showPage = ref(false)
        const getProject = async () => {
            try {
                const { details } = await getProjectApi({
                    uuid: projectUuId.value
                })
                Object.keys(details).forEach((key) => {
                    set(detailObj, key, details[key])
                })
                console.log(detailObj)
                if (detailObj.scan_number == -1) {
                    await getLastImportTokensRecord()
                } else {
                    scanInfo.getQrcode()
                }
                showPage.value = true
            } catch (error) {
                showError(error)
            }
        }
        getProject()

        const tokenObj: any = reactive({})

        const getTokenLink = (token: string) => {
            return `${config.h5Server}/detail/${token}`
        }

        const getLastImportTokensRecord = async () => {
            try {
                const res = await getLastImportTokensRecordApi({
                    uuid: projectUuId.value
                })
                Object.keys(res).forEach((key) => {
                    set(tokenObj, key, res[key])
                })
                listInfo.init()
            } catch (error) { }
        }

        const tokensUploadInfo = reactive({
            loading: false,
            isSuccess: false,
            before: (file: File) => {
                return uploadHandle(file, 4)
            },
            request: async (value: any) => {
                tokensUploadInfo.loading = true
                tokensUploadInfo.isSuccess = false
                try {
                    await importTokensApi({
                        file: value.file,
                        uuid: projectUuId.value
                    })
                    getLastImportTokensRecord()
                    tokensUploadInfo.isSuccess = true
                    setTimeout(() => {
                        tokensUploadInfo.isSuccess = false
                    }, 3000)
                } catch (error) {
                    showError(error)
                }
                tokensUploadInfo.loading = false
            }
        })

        const exportTokensInfo = reactive({
            loading: false,
            export: async () => {
                exportTokensInfo.loading = true
                try {
                    const data = await exportTokensApi({
                        uuid: projectUuId.value
                    })
                    exportFile(data, <string>root.$i18n.t('projectInvite.exportListCsv', { name: detailObj.project_name }))
                } catch (error) {
                    showError(error)
                }
                exportTokensInfo.loading = false
            }
        })

        const downloadTemplate = async () => {
            try {
                const data = await getTokenTemplate()
                exportFile(data, <string>root.$i18n.t('projectInvite.templateCsv'))
            } catch (error) {
                showError(error)
            }
        }

        const tokenList: Ref<any[]> = ref([])

        const listInfo = reactive({
            searchWatermarkText: '',
            page: 1,
            size: 20,
            loading: false,
            total: 0,
            onLoad: () => getTokens(),
            init: () => {
                tokenList.value = []
                listInfo.page = 1
                getTokens()
            }
        })

        const getTokens = async () => {
            listInfo.loading = true
            try {
                const filters: any[] = [['watermark_id', 'like', listInfo.searchWatermarkText]]
                const { items, total } = await getTokensApi({
                    uuid: projectUuId.value,
                    page: listInfo.page - 1,
                    size: listInfo.size,
                    filters: JSON.stringify(filters),
                })
                listInfo.total = total
                tokenList.value = [...items]
            } catch (error) {
                showError(error)
            }
            listInfo.loading = false
        }

        const scanInfo = reactive({
            loading: false,
            isShow: false,
            getScanLink: async () => {
                scanInfo.loading = true
                try {
                    await getScanLinkApi({
                        uuid: projectUuId.value
                    })
                    root.$message.success(<string>root.$i18n.t('success'))
                    scanInfo.isShow = false
                    getProject()
                } catch (error) {
                    showError(error)
                }
                scanInfo.loading = false
            },
            qrcode: '',
            link: `${config.apiServer}/api/v1/project/${projectUuId.value}/new-token`,
            getQrcode: async () => {
                scanInfo.qrcode = await qrcode.toDataURL(scanInfo.link)
            }
        })

        const onSearchInput = _.debounce(listInfo.init, 1000)

        return {
            scanInfo,
            projectUuId,
            detailObj,
            tokensUploadInfo,
            fileTypes,
            tokenObj,
            exportTokensInfo,
            downloadTemplate,
            tokenList,
            listInfo,
            ProjectTokenStatus,
            getTokenLink,
            showPage,
            userInfo,
            UserRole,
            onSearchInput,
        }
    }
})
